import styled from 'styled-components'

const Text = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #0049ee;
  max-width: 300px;
  margin: 0 auto;
`

export default Text
