import React from 'react'
import styled from 'styled-components'

import AdsList from '../../molecules/AdsList'
import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const StyledTitle = styled(HomeSectionTitle)`
  text-align: center;

  ${media.greaterThan('sm')`
    margin-left: 3rem;
    text-align: left;
  `}
`

const HomeNewAds = () => (
  <Container>
    <StyledTitle tag='h2'>
      Les dernières nouveautés
    </StyledTitle>
    <AdsList />
  </Container>
)

export default HomeNewAds
