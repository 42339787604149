import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, trim, size, isEmpty, isFunction } from 'lodash'

import media from '../../../utils/media'
import theme from '../../../config/theme'
import FormItem from '../../atoms/FormItem'

const InputContainer = styled.div`
  position: relative;
`

const StyledInput = styled(FormItem)`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  line-height: 1.2;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 12px 16px 12px ${({ icon }) => isEmpty(icon) ? '16px' : '46px'};
  border-radius: 48px;
  border: 1px solid ${({ theme, error }) => error ? get(theme, 'brightOrange', '#FFF') : 'transparent'};
  box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);
  outline: none;

  &::placeholder {
    font-size: 18px;
    line-height: 1.2;
    opacity: 0.5;
  }
`

const Icon = styled(FontAwesomeIcon)`
  && {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
  }
`

const Suggestion = styled.div`
  font-family: 'Source Sans Pro';
  padding: 16px 16px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => get(theme, 'paleBlue', '#FFF')};
  }
`

const SuggestionContainer = styled.div`
  position: absolute;
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};
  background: ${({ theme }) => get(theme, 'white', '#FFF')};
  min-width: 100%;
  margin-top: 4px;
  border-radius: 16px;
  z-index: 2;
  box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.2);

  ${media.lessThan('md')`
    width: 80%;
  `}
`

const InputAutosuggest = ({
  icon, inputProps, getSuggestions, setFieldValue, renderLabel, onSuggestionSelected, renderSuffix,
  renderSuggestion, ...props
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [, setRenderingSuggestions] = useState(false)

  const renderInputComponent = inputProps => (
    <InputContainer>
      <StyledInput
        icon={icon}
        {...inputProps}
        {...props} />
      {!isEmpty(icon) &&
        <Icon
          icon={icon}
          color={get(theme, get(inputProps, 'error') ? 'brightOrange' : 'blue', '#FFF')} />
      }
    </InputContainer>
  )

  const renderSuggestionHandler = item => (
    <Suggestion id={get(inputProps, 'name')}>
      {renderSuggestion(item)}
    </Suggestion>
  )

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <SuggestionContainer {...containerProps}>
      {children}
    </SuggestionContainer>
  )

  const getSuggestionValue = value => value
  const onSuggestionSelectedFunc = isFunction(onSuggestionSelected)
    ? onSuggestionSelected
    : event => setFieldValue(get(inputProps, 'name'), get(event, 'target.textContent', ''))
  const onSuggestionSelectedHooked = (...arg) => {
    setRenderingSuggestions(false)
    return onSuggestionSelectedFunc(...arg)
  }
  const onSuggestionsClearRequested = () => {
    setRenderingSuggestions(false)
    setSuggestions([])
  }
  const onSuggestionsFetchRequested = async ({ value }) => {
    const options = await getSuggestions(value)
    setSuggestions(options)
  }
  const shouldRenderSuggestions = (value) => {
    setRenderingSuggestions(true)
    return size(trim(value)) > 1
  }

  return (
    <Autosuggest
      inputProps={{
        ...inputProps,
        id: get(inputProps, 'name')
      }}
      suggestions={suggestions}
      renderSuggestion={renderSuggestionHandler}
      getSuggestionValue={getSuggestionValue}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={onSuggestionSelectedHooked}
      shouldRenderSuggestions={shouldRenderSuggestions}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested} />
  )
}

InputAutosuggest.propTypes = {
  icon: PropTypes.string,
  inputProps: PropTypes.object.isRequired,
  renderLabel: PropTypes.func,
  renderSuffix: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  onSuggestionSelected: PropTypes.func
}

InputAutosuggest.defaultProps = {
  icon: '',
  renderLabel: value => value,
  renderSuffix: value => null,
  renderSuggestion: value => value
}

export default InputAutosuggest
