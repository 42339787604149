import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '485'
  },
  xl: {
    height: '405'
  }
}

const HomeConcept = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "realestate-agent.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Un réseau de conseillers partout en France pour vous trouver le bien idéal'
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Un réseau de conseillers partout en France pour vous trouver le bien idéal
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Vous souhaitez acheter un logement neuf pour votre résidence principale ou pour un investissement locatif&nbsp;?
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Nos conseillers vous accompagnent de la recherche jusqu’à la livraison du bien.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/programmes-immobiliers-neufs/'
          title='Lancer votre recherche'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default HomeConcept
