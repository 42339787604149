import React from 'react'
import styled from 'styled-components'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { map, size, slice, floor } from 'lodash'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

import partners from '../../../data/partners'
import getMediaSize from '../../../utils/getMediaSize'
import PartnerImage from '../../molecules/PartnerImage'
import HomePartTitle from '../../atoms/HomeSectionTitle'
import useWindowSize from '../../../utils/useWindowSize'

const StyledHomePartTitle = styled(HomePartTitle)`
  margin-bottom: 48px;
  text-align: center;
`

const StyledSplide = styled(Splide)`
  margin-bottom: 16px;
`

const HomePartners = () => {
  const partnersSize = size(partners)

  const { width } = useWindowSize()
  const isDesktop = width > getMediaSize('xl')

  return (
    <>
      <StyledHomePartTitle>Nos partenaires promoteurs</StyledHomePartTitle>
      {map([slice(partners, 0, floor(partnersSize / 2)), slice(partners, floor(partnersSize / 2), partnersSize)], chunkPartners => (
        <StyledSplide
          options={{
            type: 'loop',
            drag: 'free',
            arrows: false,
            pagination: false,
            perPage: isDesktop ? 7 : 3,
            autoScroll: {
              pauseOnHover: false,
              pauseOnFocus: false,
              rewind: false,
              speed: 1
            }
          }}
          extensions={{ AutoScroll }}>
          {map(chunkPartners, partner => (
            <SplideSlide>
              <PartnerImage name={partner} />
            </SplideSlide>
          ))}
        </StyledSplide>
      ))}
    </>
  )
}

export default HomePartners
