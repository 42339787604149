import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import Row from '../../atoms/Row'
import Col from '../../atoms/Col'
import media from '../../../utils/media'

const TitleCol = styled(Col)`
  text-align: ${props => get(props, 'center', false) ? 'center' : 'left'};
`

const Title = styled.h2`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: ${({ center }) => center ? 'center' : 'left'};
  ${({ center }) => center ? 'margin: 0 auto;' : ''}
  color: #0049ee;

  ${media.lessThan('sm')`
    font-size: 22px;
  `}
`

const Border = styled.hr`
  color: #ff9900;
  background-color: #ff9900;
  width: 314px;
  height: 4px;
  border-radius: 20px;
`

const HomeTitle = ({ children, className, center, border }) => (
  <Row>
    <TitleCol>
      <Title center={center} className={className}>
        {children}
      </Title>
      {border && <Border />}
    </TitleCol>
  </Row>
)

HomeTitle.propTypes = {
  children: PropTypes.any.isRequired,
  border: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string
}

HomeTitle.defaultProps = {
  border: false,
  center: true,
  className: ''
}

export default HomeTitle
