import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '503'
  }
}


const HomeDownloadApp = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "programme-neuf.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Un réseau de conseillers partout en France pour vous trouver le bien idéal'
      size={size}
      right={false}
      imageData={placeholderImage}
      backgroundColor='paleGrey'
      maxText='400'>
      <HomeSectionTitle tag='h4'>
        Investissez dans le bien immobilier neuf idéal
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Trouvez le type d’investissement qui vous correspond en fonction de vos besoins et de votre situation personnelle.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Nous vous en disons plus sur tous les grands types d’investissement immobilier possibles et comment notre équipe peut vous accompagner de bout en bout dans ce projet.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/investissement-immobilier/'
          title="Investir dans l'immobilier"
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default HomeDownloadApp
