import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import ExpertIcon from '../../../assets/expert.inline.svg'
import PictoNewBuilding from '../../../assets/new.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoRevenuFiscal from '../../../assets/tax-income.inline.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 770px;
  margin: auto;
  padding: 0 40px;

  ${media.greaterThan('md')`
    padding: 0;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  margin: 32px 0;

  ${media.greaterThan('sm')`
    margin: 40px 0;
    gap: 40px;
  `}
`

const LineContent = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  
  ${media.lessThan('md')`
      flex-direction: column;
      display: flex;
      gap: 16px;
  `}

  & > svg {
    height: 60px;

    ${media.greaterThan('sm')`
      height: 80px;
      width: 80px;
    `}
  }
`

const LineText = styled.div`
  flex-direction: column;
  width: 80%;

  ${media.lessThan('md')`
    width: 100%;
  `}
`

const LineTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};
  width: 100%;
  margin-bottom: 8px;

  ${media.greaterThan('sm')`
    font-size: 22px;
  `}
`
const LineDescription = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};
  width: 100%;

  ${media.greaterThan('sm')`
    font-size: 16px;
  `}
`

const FundingPTZ = () => (
  <Container>
    <Content>
      <HomeSectionTitle tag='h3'>
        Quels sont les avantages d’acheter son logement neuf avec Folhomee ?
      </HomeSectionTitle>
      <LineContainer>
        <LineContent>
          <PictoNewBuilding />
          <LineText>
            <LineTitle>
              Une plateforme de recherche unique et un accompagnement sur-mesure
            </LineTitle>
            <LineDescription>
              Notre réseau de conseillers spécialistes de l’achat en résidence principale et en investissement locatif est disponible partout en France pour vous accompagner dans vos projets.
            </LineDescription>
          </LineText>
        </LineContent>
        <LineContent>
          <ExpertIcon />
          <LineText>
            <LineTitle>
              Des conseillers spécialisés et indépendants.
            </LineTitle>
            <LineDescription>
              Spécialiste de la vente dans le neuf, Folhomee occupe une position de choix sur le marché en vous offrant un large panel de biens immobiliers parmi plus de 200 promoteurs immobiliers nationaux et régionaux partenaires. Notre force réside dans notre indépendance à vous proposer les biens qui vous correspondent.
            </LineDescription>
          </LineText>
        </LineContent>
        <LineContent>
          <PictoRevenuFiscal />
          <LineText>
            <LineTitle>
              Des experts de l’investissement et de la défiscalisation
            </LineTitle>
            <LineDescription>
              Nous sommes conscients qu’il est toujours désagréable de recevoir des notifications pour des biens immobiliers qui ne vous correspondent pas. Pour cela, il vous est possible de renseigner plus de 20 critères de recherche sur notre plateforme.
            </LineDescription>
          </LineText>
        </LineContent>
      </LineContainer>
    </Content>
  </Container>
)

export default FundingPTZ
