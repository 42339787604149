import React from 'react'
import styled from 'styled-components'

import Row from '../../atoms/Row'
import Col from '../../atoms/Col'
import HomeTitle from '../../atoms/HomeTitle'
import LaunchButton from '../../atoms/LaunchButton'

const TitleCol = styled(Col)`
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
`

const ButtonCol = styled(Col)`
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HomeBottom = () => (
  <>
    <Row>
      <TitleCol size={12}>
        <HomeTitle>Le bien de vos rêves est à portée de main</HomeTitle>
      </TitleCol>
    </Row>
    <Row>
      <ButtonCol size={12}>
        <LaunchButton
        title= 'Voir les programmes neufs en vente'/>
      </ButtonCol>
    </Row>
  </>
)

export default HomeBottom
