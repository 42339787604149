import axios from 'axios'
import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { get, map, isEqual, find, chunk, toLower, replace, isEmpty } from 'lodash'

import media from '../../../utils/media'
import Text from '../../atoms/Text'
import SelectField from '../../atoms/SelectField'
import HomePartTitle from '../../atoms/HomeSectionTitle'
import { API_SALES_URL } from '../../../config'

const TAXATIONS = [{
  slug: 'pinel',
  label: 'Loi Pinel'
}, {
  slug: 'lmnp',
  label: 'Location Meublée Non-Professionnelle - LMNP'
}, {
  slug: 'nue',
  label: 'Nue propriété'
}, {
  slug: 'tva',
  label: 'TVA réduite'
}]

const Section = styled.div`
  margin: 0 128px 32px 128px;

  ${media.lessThan('md')`
    margin: 0 32px 0 64px;
  `}

  ${media.lessThan('sm')`
    margin: 0 24px 0 24px;
  `}
`
const StyledHomePartTitle = styled(HomePartTitle)`
  margin-bottom: 48px;

  ${media.lessThan('sm')`
    margin-bottom: 16px;
  `}
`
const Title = styled.div`
  margin: 0;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};
`
const StyledText = styled(Text)`
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
`
const Box = styled.div`
  margin-top: 16px
`
const Inline = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
`
const InlineTitle = styled(Inline)`
  gap: 16px;

  ${media.lessThan('md')`
    gap: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  `}
`
const StyledLink = styled(Link)`
  font-family: 'Source Sans Pro';
  line-height: normal;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  color: ${({ theme }) => get(theme, 'blue')};

  &:hover {
    text-decoration: underline;
  }
`
const BigStyledLink = styled(StyledLink)`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'brightOrange')};
  display: flex;
  justify-content: center;
  margin-bottom: 48px;

  ${media.lessThan('sm')`
    font-size: 16px;
    margin-bottom: 64px;
  `}
`
const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 32px;
  margin-bottom: 32px;

  ${media.lessThan('sm')`
    gap: 4px;
    display: flex;
    flex-direction: column;
  `}
`
const Block = styled.div`
  width: 33%;

  ${media.lessThan('md')`
    width: 100%;
  `}
`
const Margin = styled.div`
  margin: ${({ margin }) => margin};
`
const Divider = styled.hr`
  display: inline-block;
  height: 256px;
  border-top: 0px solid;
  border-width: 0.5px;
  border-color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('md')`
    display: block;
    height: 0;
    width: 100%;
  `}
`

const StyledSelectField = styled(SelectField)`
  width: 300px;
`

const HomePrograms = () => {
  const [regions, updateRegions] = useState([])
  const [selectedRegion, updateSelectedRegion] = useState({})
  const [investment, updateInvestment] = useState({})
  const [lotCount, updateLotCount] = useState({})
  const [programCount, updateProgramCount] = useState({})

  const cities = get(programCount, 'cities')
  const regionName = get(selectedRegion, 'name')
  const departments = get(programCount, 'departments')

  const handleChange = useCallback((_, select) => {
    const region = find(regions, region => isEqual(select, get(region, 'name')))
    updateSelectedRegion(region)
  }, [regions, updateSelectedRegion])

  const fetchRegions = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/regions`)
      const fetchedRegions = map(get(data, 'data'), (region) => ({
        id: get(region, 'attributes.id'),
        code: get(region, 'attributes.code'),
        name: get(region, 'attributes.name')
      }))

      updateRegions(fetchedRegions)
      updateSelectedRegion(find(fetchedRegions, region => isEqual(get(region, 'code'), '11')))
    } catch (err) {
      console.log(err)
    }
  }, [])

  const fetchCount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_location`, {
        params: {
          region: get(selectedRegion, 'id')
        }
      })

      updateLotCount({ total: get(data, 'total', 0), rooms: get(data, 'rooms', []) })
      updateProgramCount({ cities: get(data, 'cities'), regions: get(data, 'regions'), departments: get(data, 'departments') })
    } catch (err) {
      console.log(err)
    }
  }, [selectedRegion])

  const fetchInvestmentData = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_taxation`)
      updateInvestment(map(TAXATIONS, taxation => {
        return {
          label: get(taxation, 'label', ''),
          count: get(data, get(taxation, 'slug'), 0)
        }
      }))
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    fetchRegions()
    fetchInvestmentData()
  }, [])

  useEffect(() => {
    if (!isEmpty(selectedRegion)) {
      fetchCount()
    }
  }, [selectedRegion])

  return (
    <Section>
      <InlineTitle>
        <StyledHomePartTitle>Tous les programmes neufs en</StyledHomePartTitle>
        <StyledSelectField
          name='region'
          value={regionName}
          options={map(regions, region => get(region, 'name'))}
          onChange={handleChange}
          placeholder='Sélectionner une région' />
      </InlineTitle>
      <Container>
        {map(chunk(cities, 4), smallChunk => (
          <Block>
            {map(smallChunk, ({ name, code, count }) => {
              const cityName = toLower(replace(name, /\s/g, '-')).normalize('NFD').replace(/[\u0300-\u036f]/g, '')

              return (
                <Margin margin='0 0 16px 0'>
                  <Title>
                    <div>Programmes immobiliers neufs à</div>
                    <div>{name} ({code})</div>
                  </Title>
                  <StyledLink to={`programmes-immobiliers-neufs/${cityName}`}>
                    {count} annonces
                  </StyledLink>
                </Margin >
              )
            })}
          </Block>
        ))}
      </Container>
      <div>
        <StyledHomePartTitle>Autres biens immobiliers en France</StyledHomePartTitle>
        <Container>
          <Block>
            <Margin margin='0 0 16px 0'>
              <Title>Investissement immobilier neufs en France</Title>
            </Margin>
            {map(investment, elt => (
              <Margin margin='0 0 8px 0'>
                <Title>{get(elt, 'label')}</Title>
                <StyledText>({get(elt, 'count')} programmes)</StyledText>
              </Margin>
            ))}
          </Block>
          <Divider />
          <Block>
            <div>
              <Title>Appartement neuf en&nbsp;{regionName}</Title>
              <StyledText>{get(lotCount, 'total')} biens</StyledText>
            </div>
            <Box>
              <Title>Appartement 2 pièces en&nbsp;{regionName}</Title>
              <StyledText>{get(find(get(lotCount, 'rooms'), ({ rooms }) => isEqual(rooms, 2)), 'count', 0)} biens</StyledText>
            </Box>
            <Box>
              <Title>Appartement 3 pièces en&nbsp;{regionName}</Title>
              <StyledText>{get(find(get(lotCount, 'rooms'), ({ rooms }) => isEqual(rooms, 3)), 'count', 0)} biens</StyledText>
            </Box>
          </Block>
          <Divider />
          <Block>
            <Title>Programmes neufs en&nbsp;{regionName}</Title>
            {map(departments, ({ name, count }) => (
              <Margin margin='8px 0 8px 0'>
                <Inline>
                  <Title>{name}</Title>
                  <StyledText>{count} programmes</StyledText>
                </Inline>
              </Margin>
            ))}
          </Block>
        </Container>
        <BigStyledLink to='/programmes-immobiliers-neufs'>Voir tous les programmes en France</BigStyledLink>
      </div>
    </Section>
  )
}

export default HomePrograms
