import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'
import { Padding } from '@styled-icons/material'

const OldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  z-index: 999;
  padding: 0 24px;
  margin: auto;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
  
  ${media.greaterThan('xl')`
    max-width: 550px;
    margin-right: 50%;
    padding: 0;
    min-height: 600px;
  `}
`

const Container = styled.div`
  width: 100%;
  max-width: 600px;

  ${media.lessThan('md')`
    width:90%;
  `}
`

const HeroContainer = ({ oldType, children, ...props }) => {
  if (oldType) {
    return (
      <OldContainer {...props}>
        {children}
      </OldContainer>
    )
  }

  return (
    <Container {...props}>
      {children}
    </Container>
  )
}

HeroContainer.propTypes = {
  oldType: PropTypes.bool,
  children: PropTypes.any
}

HeroContainer.defaultProps = {
  oldType: true
}

export default HeroContainer
