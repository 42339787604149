import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'

const PartnerImage = ({ name, ...props }) => {
  const data = useStaticQuery(graphql`{
    nexity: file(relativePath: {eq: "partners/nexity.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    angelotto: file(relativePath: {eq: "partners/angelotto.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    batinantes: file(relativePath: {eq: "partners/batinantes.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    bouygues: file(relativePath: {eq: "partners/bouygues.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    ca: file(relativePath: {eq: "partners/ca.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    care: file(relativePath: {eq: "partners/care.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    catella: file(relativePath: {eq: "partners/catella.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    coffim: file(relativePath: {eq: "partners/coffim.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    cogedim: file(relativePath: {eq: "partners/cogedim.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    constructa: file(relativePath: {eq: "partners/constructa.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    duval: file(relativePath: {eq: "partners/duval.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    edelis: file(relativePath: {eq: "partners/edelis.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    edouarddenis: file(relativePath: {eq: "partners/edouarddenis.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    europeanhouse: file(relativePath: {eq: "partners/europeanhouse.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    greencity: file(relativePath: {eq: "partners/greencity.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    kaufman: file(relativePath: {eq: "partners/kaufman.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    lp: file(relativePath: {eq: "partners/lp.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    lrp: file(relativePath: {eq: "partners/lrp.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    marignan: file(relativePath: {eq: "partners/marignan.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    mocom: file(relativePath: {eq: "partners/mocom.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    nacarat: file(relativePath: {eq: "partners/nacarat.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    neoinvest: file(relativePath: {eq: "partners/neoinvest.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    },
    neovarim: file(relativePath: {eq: "partners/neovarim.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    nouveauxconstructeurs: file(relativePath: {eq: "partners/nouveauxconstructeurs.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    promomidi: file(relativePath: {eq: "partners/promomidi.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    quartus: file(relativePath: {eq: "partners/quartus.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    realites: file(relativePath: {eq: "partners/realites.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    saintagne: file(relativePath: {eq: "partners/saintagne.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    saintgeorges: file(relativePath: {eq: "partners/saintgeorges.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    sogeprom: file(relativePath: {eq: "partners/sogeprom.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    spirit: file(relativePath: {eq: "partners/spirit.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    sporting: file(relativePath: {eq: "partners/sporting.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    tagerim: file(relativePath: {eq: "partners/tagerim.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    terresens: file(relativePath: {eq: "partners/terresens.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
    urbat: file(relativePath: {eq: "partners/urbat.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }

    vinci: file(relativePath: {eq: "partners/vinci.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const image = getImage(get(data, name))

  return (
    <GatsbyImage
      alt={`${name}-folhomee partner`}
      image={image}
      fadeIn={false}
      loading='eager'
      imgStyle={{ objectFit: 'contain' }}
      {...props} />
  )
}

PartnerImage.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired
}

PartnerImage.defaultProps = {
  size: 100
}

const StyledImage = styled(PartnerImage)`
  object-fit: contain !important;
  width: ${(props) => get(props, 'size', 120)}px;
  height: ${(props) => get(props, 'size', 120)}px;

  ${media.lessThan('sm')`
    width: ${(props) => get(props, 'size', 100) * 0.6}px;
    height: ${(props) => get(props, 'size', 100) * 0.6}px;
  `}
`

export default StyledImage
