import React from 'react'
import styled from 'styled-components'

import HomeHero from '../../molecules/HomeHero'
import PhoneImage from '../../molecules/PhoneImage'
import NewTopSection from '../../molecules/NewTopSection'

const StyledPhoneImage = styled(PhoneImage)`
  display: none;
`

const HomeTopSection = () => (
  <NewTopSection component={StyledPhoneImage} absoluteImage={false}>
    <HomeHero />
  </NewTopSection>
)

export default HomeTopSection
