import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import media from '../../../utils/media'
import theme from '../../../config/theme'
import SubmitButton from '../SubmitButton'
import InputAutosuggest from '../InputAutosuggest'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${media.greaterThan('md')`
    flex-direction: row;
    border-radius: 48px;
    background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
    box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);
    background-clip: padding-box;
    border: 1px solid ${({ theme, $error }) => $error ? get(theme, 'brightOrange', '#FFF') : 'transparent'};
    max-width : 800px;
    margin: auto;
  `}
`

const AutosuggestContainer = styled.div`
  width: 100%;

  ${media.lessThan('md')`
    position: relative;
  `}
`

const Button = styled(SubmitButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  padding: 12px 32px;
  margin-top: 16px;
  border-radius: 48px;
  border: 1px solid ${({ theme }) => get(theme, 'brightOrange', '#FFF')};

  ${media.greaterThan('md')`
    width: fit-content;
    margin: 0;
  `}
`

const Input = styled(InputAutosuggest)`
  max-width: 100%;

  ${media.greaterThan('md')`
    box-shadow: none;
    border: none;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`

const SearchButton = ({
  icon, inputProps, buttonText, onClick, setFieldValue, getSuggestions, renderLabel, onSuggestionSelected,
  renderSuggestion, renderSuffix
}) => (
  <Container $error={get(inputProps, 'error')}>
    <AutosuggestContainer>
      <Input
        icon={icon}
        inputProps={inputProps}
        renderLabel={renderLabel}
        renderSuffix={renderSuffix}
        setFieldValue={setFieldValue}
        getSuggestions={getSuggestions}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected} />
    </AutosuggestContainer>
    <Button
      type='button'
      onClick={onClick}>
      <Icon
        icon='search'
        color={get(theme, 'white')} />
      <span>
        {buttonText}
      </span>
    </Button>
  </Container>
)

SearchButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  inputProps: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  renderLabel: PropTypes.func,
  renderSuffix: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func
}

SearchButton.defaultProps = {
  icon: '',
  label: '',
  onClick: () => { }
}

export default SearchButton
