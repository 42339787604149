import React from 'react'
import PropTypes from 'prop-types'
import { get, map, range } from 'lodash'
import styled, { keyframes } from 'styled-components'

const slide = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    opacity: .3;
    transform: scale(2);
  }
  100% {
    transform: scale(1)
  }
`

const Dot = styled.div`
  display: ${({ isLoaded }) => isLoaded ? 'none' : 'inline-block'};
  width: 12px;
  height: 12px;
  background: ${({ theme }) => get(theme, 'blue', '#fff')};
  border-radius: 100%;
  animation: ${slide} 1s infinite;

  &:nth-child(1) {
    animation-delay: .1s;
  }

  &:nth-child(2) {
    animation-delay: .2s;
  }

  &:nth-child(3) {
    animation-delay: .3s;
  }

  &:nth-child(4) {
    animation-delay: .4s;
  }

  &:nth-child(5) {
    animation-delay: .5s;
  }
`

const PlaceholderContainer = styled.div`
  width: 100%;
  transition: all 300ms ease-in-out;
  height: ${({ isLoaded }) => isLoaded ? 0 : 193}px;
  padding: 0 80px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

const ImagePlaceholder = ({ isLoaded, src }) => (
  <PlaceholderContainer isLoaded={isLoaded}>
    {map(range(5), idx => (
      <Dot key={`${src}-loader-dot-${idx}`} isLoaded={isLoaded} />
    ))}
  </PlaceholderContainer>
)

ImagePlaceholder.propTypes = {
  src: PropTypes.string,
  isLoaded: PropTypes.bool
}

ImagePlaceholder.defaultProps = {
  src: '',
  isLoaded: false
}

export default ImagePlaceholder
