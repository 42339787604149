import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import HomeIntro from '../components/organisms/HomeIntro'
import HomeBottom from '../components/organisms/HomeBottom'
import HomeNewAds from '../components/organisms/HomeNewAds'
import SiteSection from '../components/atoms/SiteSection/'
import HomeReviews from '../components/organisms/HomeReviews'
import HomePartners from '../components/organisms/HomePartners'
import HomePrograms from '../components/organisms/HomePrograms'
import HomeConnexion from '../components/organisms/HomeConnexion'
import HomeTopSection from '../components/organisms/HomeTopSection'
import HomeDownloadApp from '../components/organisms/HomeDownloadApp'
import HomeAdvantagesNew from '../components/organisms/HomeAdvantagesNew'

const TITLE = 'Folhomee, agrégateur d’annonces et programmes neufs'
const DESCRIPTION = 'Toutes les annonces et programmes immobiliers neufs de plus de 1000 sites internet ! Folhomee vous accompagne dans toutes les étapes de votre achat.'

const Home = ({ location }) => {
  const { site, whiteLogo } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            phone
            streetAddress
            addressLocality
            postalCode
            addressCountry
            latitude
            longitude
          }
        }
        whiteLogo: file(relativePath: {eq: "logos/logo-navbar.png"}) {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
            )
          }
        }
      }
    `)

  const siteUrl = get(site, 'siteMetadata.siteUrl')
  const imageUrl = get(whiteLogo, 'childImageSharp.gatsbyImageData.images.fallback.src')

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
        <LocalBusinessJsonLd
          id={siteUrl}
          url={siteUrl}
          type='RealEstateAgent'
          name={get(site, 'siteMetadata.author')}
          description={DESCRIPTION}
          telephone={get(site, 'siteMetadata.phone')}
          address={{
            streetAddress: get(site, 'siteMetadata.streetAddress'),
            addressLocality: get(site, 'siteMetadata.addressLocality'),
            postalCode: get(site, 'siteMetadata.postalCode'),
            addressCountry: get(site, 'siteMetadata.postalCode')
          }}
          geo={{
            latitude: get(site, 'siteMetadata.latitude'),
            longitude: get(site, 'siteMetadata.longitude')
          }}
          images={[`${siteUrl}${imageUrl}`]} />
      </SEO>
      <Navbar location={location} />
      <HomeTopSection />
      <SiteSection
        id='accompagne-achat-sur-plan'
        $first={true}>
        <HomeIntro />
      </SiteSection>
      <SiteSection id='dernieres-nouveautes'>
        <HomeNewAds />
      </SiteSection>
      <SiteSection id='reseau-conseillers'>
        <HomeConnexion />
      </SiteSection>
      <SiteSection id='avantage-achat-neuf'>
        <HomeAdvantagesNew />
      </SiteSection>
      <SiteSection id='telecharger-app'>
        <HomeDownloadApp />
      </SiteSection>
      <SiteSection id='partenaire-promoteurs'>
        <HomePartners />
      </SiteSection>
      <SiteSection id='avis'>
        <HomeReviews />
      </SiteSection>
      <SiteSection>
        <HomeBottom />
      </SiteSection>
      <>
        <HomePrograms />
      </>
      <Footer noImage={true} noForm={true} />
    </>
  )
}

Home.propTypes = {
  location: PropTypes.object.isRequired
}

export default Home
