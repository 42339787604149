import React, { useCallback, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import accents from 'remove-accents'
import { useFormik } from 'formik'
import { deserialize } from 'deserialize-json-api'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { capitalize, get, map, kebabCase, lowerCase, take, includes, filter, find, isEqual } from 'lodash'
import * as Yup from 'yup'

import media from '../../../utils/media'
import Modal from '../../molecules/Modal'
import Header from '../../atoms/HeroHeader'
import Container from '../../atoms/HeroContainer'
import ProgramsForm from '../../molecules/ProgramsForm'
import SearchButton from '../../atoms/SearchButton'
import { API_SALES_URL } from '../../../config'

const formSchema = Yup.object().shape({
  id: Yup.string().required(),
  location: Yup.string().required(),
  production: Yup.bool().required()
})

const StyledContainer = styled(Container)`
    max-width: 1000px;
    margin: auto;
  `

const TrustPilotWidgetHeader = styled.div`
    margin-top: 24px;

    & > a {
      text-decoration: none;
    }
  `

const ContentWidget = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('sm')`
      font-size: 14px;
    `}

    ${media.lessThan('md')`
      justify-content: center;
    `}
  `

const ImageContainerStars = styled.div`
    width: 150px;
    height: auto;
    margin-right: 16px;
  `

const ImageContainerLogo = styled.div`
    width: 100px;
    height: auto;
  `

const TextRatings = styled.p`
    margin: 0 16px 0 8px;
    text-shadow: 0 0 2px #002474;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => get(theme, 'white', '#FFF')};


    & > span {
      font-weight: normal;
    }
  `

const renderLabel = value => get(value, 'label', '')

const renderSuffix = props => `(${get(props, 'suffix', '')})`

const renderSuggestion = value => `${get(value, 'label', '')} (${get(value, 'district', '')})`

const HomeHero = () => {
  const [isOpen, updateIsOpen] = useState(false)
  const { citiesData, oldCitiesData } = useStaticQuery(graphql`{
    citiesData: allSqliteCity {
      edges {
        node {
          insee
          name
          zipcode
          new_page
        }
      }
    }
  }`)
  const onClose = useCallback(() => {
    updateIsOpen(false)
  }, [updateIsOpen])

  const cities = map(get(citiesData, 'edges', []), 'node')
  const oldCities = get(oldCitiesData, 'nodes', [])

  const { values, handleSubmit, handleChange, resetForm, handleBlur, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      id: '',
      insee: '',
      zipcode: '',
      location: '',
      production: false
    },
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const foundCity = find(cities, ({ insee }) => isEqual(insee, get(values, 'insee')))

      if (foundCity && isEqual(get(foundCity, 'new_page'), 1)) {
        return navigate(`/programmes-immobiliers-neufs/${kebabCase(lowerCase(accents.remove(get(foundCity, 'name'))))}`)
      }

      updateIsOpen(true)
    }
  })

  const getSuggestions = useCallback(async (value) => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/cities/search`, {
        params: {
          search: value
        }
      })

      return map(take(filter(get(deserialize(data), 'data'),
        ({ name }) => !includes(['Lyon', 'Paris', 'Marseille'], name)), 5), option => ({
          id: get(option, 'id'),
          insee: get(option, 'insee', ''),
          label: capitalize(get(option, 'name', '')),
          district: get(option, 'zipcode', ''),
          production: true
        }))
    } catch (err) {
      console.log(err)
    }
  }, [values])

  const inputProps = {
    name: 'location',
    value: get(values, 'location'),
    error: get(errors, 'location') && get(touched, 'location'),
    suffix: get(values, 'zipcode'),
    onBlur: handleBlur,
    onChange: handleChange,
    placeholder: 'Où cherchez vous ?'
  }

  const onSuggestionSelected = (_, { suggestionValue }) => {
    setFieldValue(get(inputProps, 'name'), renderLabel(suggestionValue))
    setFieldValue('id', get(suggestionValue, 'id', ''))
    setFieldValue('insee', get(suggestionValue, 'insee', ''))
    setFieldValue('zipcode', get(suggestionValue, 'district', ''))
    setFieldValue('production', get(suggestionValue, 'production', false))
  }

  return (
    <StyledContainer oldType={false}>
      <Header max='80' center={true}>
        Trouvez le bien immobilier neuf
        <br />qui vous correspond
      </Header>
      <SearchButton
        icon='crosshairs'
        onClick={handleSubmit}
        inputProps={inputProps}
        buttonText='Rechercher'
        renderLabel={renderLabel}
        renderSuffix={renderSuffix}
        setFieldValue={setFieldValue}
        getSuggestions={getSuggestions}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected} />
      <TrustPilotWidgetHeader>
        <a href='https://fr.trustpilot.com/review/folhomee.fr' target='_blank' rel="noreferrer">
          <ContentWidget>
            <TextRatings> 4.8 <span>étoiles</span></TextRatings>
            <ImageContainerStars>
              <StaticImage src='../../../images/TrustPilot/Trustpilot-ratings-5star.png' alt='4.8 étoiles sur 5' />
            </ImageContainerStars>
            <ImageContainerLogo>
              <StaticImage src='../../../images/TrustPilot/Trustpilo-brandmark.png' alt='TrustPilot' />
            </ImageContainerLogo>
          </ContentWidget>
        </a>
      </TrustPilotWidgetHeader>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ProgramsForm
          {...values}
          form='recherche home'
          onClose={onClose} />
      </Modal>
    </StyledContainer>
  )
}

export default HomeHero
