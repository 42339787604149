import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import accents from 'remove-accents'
import PropTypes from 'prop-types'
import { get, reduce, capitalize, isEqual, split, isNull, kebabCase, lowerCase, isUndefined } from 'lodash'

import ImagePlaceholder from '../ImagePlaceholder'
import RandomBackgroundImage from '../AdImage'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 193px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`

const CityText = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 7px;
  position: absolute;
  font-size: 14px;
  top: 5px;
  left: 5px;
  font-family: 'Source Sans Pro';
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  border-radius: 10px;
  background: ${({ theme, isSame }) => get(theme, isSame ? 'blue' : 'brightOrange', '#fff')};
`

const StyledImage = styled.img`
  width: 100%;
  object-fit: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  transition: all 300ms ease-in-out;
  height: ${({ isLoaded }) => !isLoaded ? 0 : 193}px;
`

const StyledRandomImage = styled(RandomBackgroundImage)`
  height: 100%;
`

const Image = ({ src, isError, updateIsError, isLoaded, onLoad, ...props }) => {
  if (isError || isUndefined(src) || isNull(src)) {
    return (
      <StyledRandomImage />
    )
  }

  return (
    <>
      <ImagePlaceholder isLoaded={isLoaded} />
      <StyledImage
        src={src}
        onLoad={onLoad}
        onError={() => updateIsError(true)}
        isLoaded={isLoaded}
        {...props} />
    </>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  onLoad: PropTypes.func,
  isError: PropTypes.bool,
  isLoaded: PropTypes.bool,
  updateIsError: PropTypes.func
}

const isSameCity = (programCity, selectedCity) => {
  if (isUndefined(programCity)) {
    return false
  }

  const selectedCities = split(selectedCity, ',')

  return reduce(selectedCities, (acc, select) => {
    return acc || isEqual(kebabCase(lowerCase(accents.remove(select))),
      kebabCase(lowerCase(accents.remove(programCity))))
  }, false)
}

const ProgramImage = ({ src, city, main, ...props }) => {
  const [isError, updateIsError] = useState(false)
  const [isLoaded, updateIsLoaded] = useState(get(props, 'loaded', true))
  const onLoad = () => updateIsLoaded(true)
  const isSame = isSameCity(city, main)

  useEffect(() => {
    updateIsError(false)
  }, [src])

  return (
    <Container>
      {!isUndefined(city) && <CityText isSame={isSame}>
        {capitalize(city)}
      </CityText>}
      <Image
        src={src}
        onLoad={onLoad}
        isError={isError}
        onError={() => updateIsError(true)}
        isLoaded={isLoaded}
        {...props} />
    </Container>
  )
}

ProgramImage.propTypes = {
  src: PropTypes.string,
  main: PropTypes.string,
  city: PropTypes.object
}

export default ProgramImage
