import React from 'react'
import styled from 'styled-components'

import HomePartTitle from '../../atoms/HomeSectionTitle'

const StyledHomePartTitle = styled(HomePartTitle)`
  margin-bottom: 48px;
  text-align: center;
`

const BlockTP = styled.div`
  margin: 0 5%;
`

const ScriptTP = () => (
  <div className='trustpilot-widget' data-locale='fr-FR' data-template-id='53aa8912dec7e10d38f59f36' data-businessunit-id='62287c92fbeb2611daaf6302' data-style-height='140px' data-style-width='100%' data-theme='light' data-stars='1,2,3,4,5' data-review-languages='fr' data-font-family='Source Sans Pro' data-text-color='#0049EE'>
    <a href='https://fr.trustpilot.com/review/folhomee.fr' target='_blank' rel='noopener noreferrer' style={{ display: 'none' }}>Trustpilot</a>
  </div>
)

const HomeReviews = () => (
  <>
    <StyledHomePartTitle>Ils en parlent mieux que nous</StyledHomePartTitle>
    <BlockTP>
      <ScriptTP />
    </BlockTP>
  </>
)

export default HomeReviews
