import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import media from '../../../utils/media'

const Header = styled.h1`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 24px;
  text-shadow: 0 3px 6px rgba(18, 59, 178, 0.79);
  color: ${({ theme }) => get(theme, 'white', '#FFF')};
  text-align: ${props => get(props, 'center', false) ? 'center' : 'left'};

  ${media.greaterThan('sm')`
    font-size: 40px;
    margin-bottom: 32px;
  `}
`

Header.propTypes = {
  max: PropTypes.number,
  center: PropTypes.bool
}

Header.defaultProps = {
  max: 70,
  center: false
}

export default Header
