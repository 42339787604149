import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const HomeIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "topview-plan.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
  <ImageSection
    fixed={true}
    right={false}
    maxText='450'
    imageData={placeholderImage}
    backgroundColor='white'>
    <HomeSectionTitle tag='h2'>
      Folhomee vous accompagne dans votre achat sur plan
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Notre plateforme d’agrégation de programmes immobiliers neufs, associée à notre réseau de conseillers immobiliers locaux vous permettent de trouver le bien neuf qui vous correspond en un temps record.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Nous vous accompagnons sans frais supplémentaire de la recherche du bien jusqu’à sa livraison.
    </HomeSectionDescription>
  </ImageSection>
  )
}

export default HomeIntro
